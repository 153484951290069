import _dotenv from "dotenv";
import _fs from "fs";
import _MissingEnvVarsError from "./MissingEnvVarsError.js";
import _process from "process";
var exports = {};
var process = _process;
const dotenv = _dotenv;
const fs = _fs;
const MissingEnvVarsError = _MissingEnvVarsError;

function difference(arrA, arrB) {
  return arrA.filter(a => arrB.indexOf(a) < 0);
}

function compact(obj) {
  const result = {};
  Object.keys(obj).forEach(key => {
    if (obj[key]) {
      result[key] = obj[key];
    }
  });
  return result;
}

exports = {
  config: function (options = {}) {
    const dotenvResult = dotenv.config(options);
    const example = options.example || options.sample || ".env.example";
    const allowEmptyValues = options.allowEmptyValues || false;
    const processEnv = allowEmptyValues ? process.env : compact(process.env);
    const exampleVars = dotenv.parse(fs.readFileSync(example));
    const missing = difference(Object.keys(exampleVars), Object.keys(processEnv));

    if (missing.length > 0) {
      throw new MissingEnvVarsError(allowEmptyValues, options.path || ".env", example, missing, dotenvResult.error);
    } // Key/value pairs defined in example file and resolved from environment


    const required = Object.keys(exampleVars).reduce((acc, key) => {
      acc[key] = process.env[key];
      return acc;
    }, {});
    const error = dotenvResult.error ? {
      error: dotenvResult.error
    } : {};
    const result = {
      parsed: dotenvResult.error ? {} : dotenvResult.parsed,
      required: required
    };
    return Object.assign(result, error);
  },
  parse: dotenv.parse,
  MissingEnvVarsError: MissingEnvVarsError
};
exports.MissingEnvVarsError = MissingEnvVarsError;
export default exports;
export const config = exports.config;
const _MissingEnvVarsError2 = exports.MissingEnvVarsError;
export { _MissingEnvVarsError2 as MissingEnvVarsError };